import React, { PropTypes, Component } from 'react';
import './ContentBlock.css';
import SliderBlock from '../SliderBlock';
import SubmenuBlock from '../SubmenuBlock';

class ContentBlock extends React.Component {
	constructor(props) {
	    super(props)
	    this.state = {
	      isSubmenuVisible: true
	    }

	    this.submenuChange = this.submenuChange.bind(this);
	}
	submenuChange() {
		this.setState(state => ({
			isSubmenuVisible: !state.isSubmenuVisible
		}))
	}
	render() {
		return (
			<>
				<SliderBlock submenuChange={this.submenuChange} />
				<SubmenuBlock isSubmenuVisible={this.state.isSubmenuVisible} />
			</>
		);
	}
}

export default ContentBlock;