import React, { PropTypes, Component } from 'react';
import './SliderBlock.css';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination, A11y]);

class SliderBlock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slides: [
				{
					'image': '/images/slider1.jpg',
				},
				{
					'image': '/images/slider2.jpg',
				},
			]
		};
	}
	loopSlides(item, i) {
		return (
			<SwiperSlide key={i}>
				<img className="mw-100" src={item.image} alt={i} />
			</SwiperSlide>
		)
    }
	render() {
		return (
			<>
				<button onClick={this.props.submenuChange}>change SUB MENU</button>
				<Swiper
					spaceBetween={50}
					slidesPerView={1}
					navigation
					pagination={{ clickable: true }}
				>
					{
						this.state.slides.map(this.loopSlides)
					}
				</Swiper>
			</>
		);
	}
}

export default SliderBlock;