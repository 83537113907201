import React, { PropTypes, Component, useState } from 'react';
import ReactDOM from 'react-dom';
import './HeaderBlock.css';
import { withTranslation } from 'react-i18next';
import { config } from '../../settings';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../../images/logo.png';

class HeaderBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: true
    }

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(id) {
    console.log(id)
    this.setState(state => ({
      isVisible: !state.isVisible
    }))
  }
	render() {
		const { t, i18n } = this.props;
		// console.log('>> ' + i18n.language)
		return (
			<>
{/*

        <Navbar expand="lg" className="p-0 h-100">
          <div className="container h-100">
            <Navbar.Brand href="#home" className="p-0 mr-5">
              <img
                src={logo}
                width="170"
                height="69"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="h-100">
              <div className="main-menu h-100 d-flex">
                <a href="#home" className="d-flex h-100 align-items-center px-3 active">Продукция</a>
                <a href="#link" className="d-flex h-100 align-items-center px-3">Полезная информация</a>
                <a href="#link2" className="d-flex h-100 align-items-center px-3">Полезные инструменты</a>
                <a href="#link3" className="d-flex h-100 align-items-center px-3">Где купить</a>
              </div>
              <div className="lang-menu ml-auto">
                <a href="" className="mx-2 active">UA</a>
                <a href="" className="mx-2">EN</a>
                <a href="" className="mx-2">RU</a>
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>

*/}
        

        <Navbar bg="light" expand="lg">
          <div className="container">
            <Navbar.Brand href="#home">
              <img src={logo} width="170" height="69" className="d-inline-block" alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#link">Link</Nav.Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav className="ml-auto">
                <Nav.Link href="#">UA</Nav.Link>
                <Nav.Link href="#">EN</Nav.Link>
              </Nav>

              <button onClick={this.handleClick.bind(this, 5)}>button</button>
              <div className={this.state.isVisible ? 'd-inline-block' : 'd-none'}>{t('title')}</div>

            </Navbar.Collapse>
          </div>
        </Navbar>

			</>
		);
	}
}

const HeaderBlockComponent = withTranslation('common')(HeaderBlock)

export default HeaderBlockComponent;