import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import HeaderBlock from './components/HeaderBlock';
import ContentBlock from './components/ContentBlock';
import PageBlock from './components/PageBlock';
import './i18n';
import './App.scss';
import './css/style.css';

const header_block = document.getElementById('header_block');
const home_page_block = document.getElementById('home_page_block');
const static_page_block = document.getElementById('static_page_block');

ReactDOM.render(
    <Suspense fallback={null}>
        <HeaderBlock />
   	</Suspense>,
    header_block
);

if (home_page_block !== null) {
	ReactDOM.render(
		<ContentBlock />,
	    home_page_block
	);
}

if (static_page_block !== null) {
	ReactDOM.render(
		<PageBlock />,
	    static_page_block
	);
}