import React, { PropTypes, Component } from 'react';
import './SubmenuBlock.css';

class SubmenuBlock extends React.Component {
	constructor(props) {
		super(props);

	}
	render() {
		return (
			<div className={this.props.isSubmenuVisible ? 'd-inline-block' : 'd-none'}>Submenu TEXT</div>
		);
	}
}

export default SubmenuBlock;