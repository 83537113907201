import React, { PropTypes, Component } from 'react';
import './PageBlock.css';

class PageBlock extends React.Component {
	constructor(props) {
		super(props);

	}
	render() {
		return (
			<p>sdfasdf asfas dfasf asdfs dfasf asdfsdfsdfasdf asfas dfasf asdfsdf</p>
		);
	}
}

export default PageBlock;